<template>
  <v-container
    ><v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-layout row wrap justify-center v-if="employees">
      <v-flex xs12 sm6 md6 lg6 class="text-left">
        <v-avatar size="40">
          <v-img :src="employees.avatar" style="cursor: pointer">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  width="5"
                  indeterminate
                  :color="$store.state.primaryColor"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <span class="text-h5 font-weight-bold">
          {{
            employees.names.first +
            " " +
            employees.names.middle +
            " " +
            employees.names.last
          }}</span
        >
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm6 md6 lg6 class="text-right">
        <div v-if="leaveDetails">
          <div v-if="userAccess == 1">
            <v-btn
              v-if="daysLeft > 1 && leaveDetails.requestStatus == 'approved'"
              class="ma-2"
              outlined
              small
              color="success"
            >
              {{ $t("on leave") }}
            </v-btn>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-start class="mb-2">
      <v-flex xs12 sm12 md6 lg6>
        <v-btn
          v-if="userAccess == 1"
          small
          class="mx-1"
          :disabled="leaveDetails.requestStatus == 'approved'"
          left
          color="success"
          @click="acceptLeave()"
          >{{ $t("approve") }}</v-btn
        >
        <v-btn
          v-if="userAccess == 1"
          small
          :disabled="leaveDetails.requestStatus == 'denied'"
          class="mx-1 white--text"
          left
          color="red"
          @click="denyLeave()"
          >{{ $t("deny") }}</v-btn
        >
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm12 md6 lg6 class="text-right">
        <v-btn
          outlined
          x-small
          color="success"
          v-if="leaveDetails.requestStatus == 'approved'"
        >
          {{ $t("approved") }}
        </v-btn>
        <v-btn
          outlined
          x-small
          :color="$store.state.secondaryColor"
          v-else-if="leaveDetails.requestStatus == 'pending'"
          >{{ $t("pending") }}
        </v-btn>
        <v-btn outlined x-small color="red" v-else>{{ $t("denied") }} </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <!-- ############################### -->
      <v-layout row wrap justify-start>
        <v-flex xs12 sm12 md12 lg12>
          <span class="text-h4 font-weight-black">{{
            leaveDetails.name.first +
            " " +
            leaveDetails.name.middle +
            " " +
            leaveDetails.name.last
          }}</span>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify="center" align="center">
        <v-flex
          class="pa-5 mx-1 elevation-2"
          style="border: 1px solid; border-radius: 5px"
        >
          <v-list dense class="elevation-1">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("leave type") }}:
                </span>
                <span class="">{{ leaveDetails.leaveType }} </span>
              </v-col>
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("number of days") }}:
                </span>
                <span class="">{{ leaveDetails.numberOfDays }} </span>
              </v-col>
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("starting from") }}:
                </span>
                <span class="">{{ leaveDetails.startDate }} </span>
              </v-col>
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("ending on") }} :
                </span>
                <span class="">{{ leaveDetails.endDate }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
    <!-- ################################### -->

    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackbarSuccess"
    >
      {{ $t("employee leave status successfully updated") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackbarSuccess = false"
          :timeout="4000"
        >
          <v-icon> Close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not update leave status. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackbarError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="approveLeaveDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to approve leave for") }}
            <span class="font-weight-bold">{{
              leaveDetails.name.first +
              " " +
              leaveDetails.name.middle +
              " " +
              leaveDetails.name.last
            }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="approveLeaveDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="updateLeaveStatus()"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="denyLeaveDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to deny leave for") }}
            <span class="font-weight-bold">{{
              leaveDetails.name.first +
              " " +
              leaveDetails.name.middle +
              " " +
              leaveDetails.name.last
            }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="denyLeaveDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="denyLeaveStatus()"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ########### -->
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import { digits } from "@/plugins/functions";
import { format, differenceInDays } from "date-fns";
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    todaysDate: "2022-01-03",
    modal: false,
    loading: true,
    approveLeaveDialog: false,
    pleaseWait: true,
    denyLeaveDialog: false,
    employeeName: "",
    search: "",
    leaveDetails: "",
    daysLeft: "",
    leaveRange: [],
    employeeId: "",
    leave: {
      employeeId: "",
      startDate: "",
      endDate: "",
      numberOfDays: 0,
      leaveType: "",
      requestStatus: "pending",
      name: {},
    },

    employees: [],
    snackbarSuccess: false,
    snackbarError: false,
  }),

  computed: {
    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
    headers() {
      return [
        {
          text: this.$t("employee ID"),
          value: "employeeId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("number of days"),
          value: "numberOfDays",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("start date"),
          value: "startDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("end date"),
          value: "endDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("request status"),
          value: "requestStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("type of leave"),
          value: "leaveType",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
    leaveTypes() {
      return [
        { name: this.$t("sick leave") },
        { name: this.$t("maternity leave") },
        { name: this.$t("paternity leave") },
        { name: this.$t("bereavement leave") },
        { name: this.$t("compensatory leave") },
        { name: this.$t("sabbatical leave") },
        { name: this.$t("unpaid leave") },
      ];
    },
  },

  created() {
    this.getEmployeeDetails();
    this.getLeaveDetails();
    this.checkAccessLevel();
  },

  methods: {
    digits,
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();
          this.loading = false;
          this.employeeId = this.employees.employeeId;
          this.leave.name.first = this.employees.names.first;
          this.leave.name.middle = this.employees.names.middle;
          this.leave.name.last = this.employees.names.last;

          this.leave.avatar = this.employees.avatar;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getLeaveDetails() {
      this.leaveDetails = "";

      db.collection("leaves")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.leaveDetails = doc.data();
          this.calculateLeaveStatus();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    calculateLeaveStatus() {
      if (this.leaveDetails) {
        this.todaysDate = format(new Date(), "yyyy-MM-dd");

        this.daysLeft = differenceInDays(
          new Date(
            this.leaveDetails.endDate.substring(0, 4),
            this.leaveDetails.endDate.substring(5, 7),
            this.leaveDetails.endDate.substring(8, 10)
          ),
          new Date(
            this.todaysDate.substring(0, 4),
            this.todaysDate.substring(5, 7),
            this.todaysDate.substring(8, 10)
          )
        );
      }
    },

    //HR approves leave
    updateLeaveStatus() {
      this.loading = true;
      db.collection("leaves")
        .doc(this.leaveId)
        .update({
          requestStatus: "approved",
        })
        .then(() => {
          this.loading = false;
          this.approveLeaveDialog = false;
          this.snackbarSuccess = true;
          this.sendNotificationApproved();
          this.getLeaveDetails();
          this.getEmployeeDetails();
        })
        .catch(() => {
          this.approveLeaveDialog = false;
          this.snackbarError = true;
        });
    },
    sendNotificationApproved() {
      let data = {
        message:
          "Hi, Your leave request starting from " +
          this.leaveDetails.startDate +
          " has been Approved.",
        ownerId: this.leaveDetails.employeeId,
        dateTime: new Date(),
        status: "unread",
      };

      db.collection("notifications")
        .add(data)
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendNotificationDenied() {
      let data = {
        message:
          "Hi, Your leave request starting from " +
          this.leaveDetails.startDate +
          " has been Denied.",
        ownerId: this.leaveDetails.employeeId,
        dateTime: new Date(),
        status: "unread",
      };

      db.collection("notifications")
        .add(data)
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //HR deny leave
    denyLeaveStatus() {
      this.loading = true;
      db.collection("leaves")
        .doc(this.leaveId)
        .update({
          requestStatus: "denied",
        })
        .then(() => {
          this.loading = false;
          this.denyLeaveDialog = false;
          this.snackbarSuccess = true;
          this.sendNotificationDenied();
          this.getLeaveDetails();
          this.getEmployeeDetails();
        })
        .catch(() => {
          this.denyLeaveDialog = false;
          this.snackbarError = true;
        });
    },

    calculateNumberOfDays() {
      this.leave.startDate = this.leaveRange[0];
      this.leave.endDate = this.leaveRange[1];

      this.leave.numberOfDays = differenceInDays(
        new Date(
          this.leave.endDate.substring(0, 4),
          this.leave.endDate.substring(5, 7),
          this.leave.endDate.substring(8, 10)
        ),
        new Date(
          this.leave.startDate.substring(0, 4),
          this.leave.startDate.substring(5, 7),
          this.leave.startDate.substring(8, 10)
        )
      );
    },
    acceptLeave() {
      this.leaveId = this.$route.params.id;
      this.approveLeaveDialog = true;
    },

    denyLeave() {
      this.leaveId = this.$route.params.id;
      this.denyLeaveDialog = true;
    },
    formatDate(val) {
      return format(val, "EEE, dd MMMM, yyyy");
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
